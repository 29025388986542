import './i18n';
import App from "./app";
import React, {Suspense} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import Loader from "./components/loader";
import {createRoot} from 'react-dom/client';

(() => {
    const root = createRoot(document.getElementById('root')!);
    root.render(
        <React.StrictMode>
            <Suspense fallback={<Loader/>}>
                <Router>
                    <App/>
                </Router>
            </Suspense>
        </React.StrictMode>);
})();
